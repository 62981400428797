import React, { useEffect, useState } from "react";
import { CircularProgress, makeStyles, Popover } from "@material-ui/core";
import * as moment from "moment";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import Selector from "../components/Selector";
import Footer from "../components/Footer";
import DaySelector from "../components/DaySelector";
import DurationChip from "../components/DurationChip";
import TimeChip from "../components/TimeChip";
import { BASE_URL } from "../api/baseurl";
import {
  setLocalStorageData,
  getLocalStorageData,
} from "../util/localStorageService";
import Toaster from "../components/Toaster";
import { ReactComponent as ArrowIcon } from "../assets/images/Arrow.svg";
import Grid from "@material-ui/core/Grid";
import processDuration from "../config/processDuration";
import ReactGA from "react-ga";
import MetaTitle from "./MetaTitle";
import { Resourcetimings } from "../constant/ResourceTiming";

const useStyles = makeStyles((theme) => ({
  fullPage: {
    width: `calc(100%)`,
    minHeight: "-webkit-fill-available",
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    overflow: "hidden",
  },
  restOfPage: {
    width: "100%",
    maxWidth: "1100px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    textAlign: "center",
    fontSize: 24,
    fontWeight: 500,
    fontStyle: "normal",
    width: "100%",
    marginBottom: 2,
    marginLeft: 10,
    color: "#33334F",
    fontFamily: "airbnb-cereal-app",
  },
  subTitle: {
    textAlign: "left",
    fontSize: 18,
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#33334F",
    fontFamily: "airbnb-cereal-app",
  },
  serviceAndLocationSelector: {
    display: "flex",
    justifyContent: "center",
  },
  backAndSelector: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    paddingLeft: 10,
    paddingRight: 10,
    background: "#FFFFFF",
    boxShadow: "0px 6px 40px rgba(208, 208, 208, 0.2)",
    borderRadius: "16px",
    marginLeft: "38px",
    [theme.breakpoints.up("xs")]: {
      marginLeft: "20px",
      width: `calc(100% - 20%)`,
    },
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - 55%)`,
    },
  },
  Selectors: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 10,
    height: "75px",
  },
  verticalLine: {
    borderLeft: "2px solid #D0D0D0",
    height: "40px",
    marginTop: "15px",
  },
  DurationChipsContainer: {
    marginTop: 25,
    display: "flex",
    padding: "10px 20px",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    boxShadow: "0px 6px 40px rgba(208, 208, 208, 0.4)",
    borderRadius: "16px",
    [theme.breakpoints.up("xs")]: {
      marginLeft: "10px",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "10px",
      width: `calc(100% - 65%)`,
    },
  },
  DurationChipsContainerParent: {
    display: "flex",
    justifyContent: "center",
    width: `calc(100% - 0%)`,
  },
  optionsHolder: {
    display: "flex",
    width: `calc(100% - 40px)`,
    flexDirection: "column",
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  unavailableSlots: {
    fontSize: "18px",
    fontWeight: "normal",
    fontFamily: "airbnb-cereal-app",
    textAlign: "center",
    color: "#000000",
    lineHeight: "140%",
    padding: "30px 20px",
  },
  subOptionsHolder: {
    background: "#FFFFFF",
    boxShadow: "0px 6px 40px rgba(208, 208, 208, 0.2)",
    borderRadius: "16px",
    display: "flex",
    width: `calc(100% - 20px)`,
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 20,
    justifyContent: "flex-start",
    marginTop: 10,
    marginBottom: 10,
  },
  chipHolder: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "flex-start",
  },
  popoverDurationHolder: {
    display: "flex",
    padding: 10,
    alignItems: "center",
    justifyContent: "space-between",
  },
  grid: {
    display: "flex",
    padding: 20,
    border: "1px solid #CCCCCC",
    borderRadius: 30,
  },
  loadingText: {
    marginTop: "10px",
    textAlign: "center",
  },
  loadingIcon: {
    color: "#535050",
  },
  icon: {
    border: "1px solid #535050",
    borderRadius: 10,
    fontSize: 34,
    fontWeight: "600",
  },
  backBtnWrapper: {
    cursor: "pointer",
    padding: "10px 20px",
    paddingLeft: 20,
    alignSelf: "baseline",
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  bookingText: {
    fontFamily: "airbnb-cereal-app",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    marginLeft: "-47px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "-27px",
    },
  },
  alertText:{
    fontSize: 14,
    fontFamily: "airbnb-cereal-app-light",
    color: "#000000",
    fontWeight: "normal",
    fontSize: "14px",
    textAlign: "center",
    lineHeight: "140%",
    margin: 0,
  },
}));

const timeToIndex = (time) => {
  //\n hack to work in Safari
  switch (time.replace("\n", "")) {
    case "06:00 AM":
      return 0;
    case "06:30 AM":
      return 1;
    case "07:00 AM":
      return 2;
    case "07:30 AM":
      return 3;
    case "08:00 AM":
      return 4;
    case "08:30 AM":
      return 5;
    case "09:00 AM":
      return 6;
    case "09:30 AM":
      return 7;
    case "10:00 AM":
      return 8;
    case "10:30 AM":
      return 9;
    case "11:00 AM":
      return 10;
    case "11:30 AM":
      return 11;
    case "12:00 PM":
      return 12;
    case "12:30 PM":
      return 13;
    case "01:00 PM":
      return 14;
    case "01:30 PM":
      return 15;
    case "02:00 PM":
      return 16;
    case "02:30 PM":
      return 17;
    case "03:00 PM":
      return 18;
    case "03:30 PM":
      return 19;
    case "04:00 PM":
      return 20;
    case "04:30 PM":
      return 21;
    case "05:00 PM":
      return 22;
    case "05:30 PM":
      return 23;
    case "06:00 PM":
      return 24;
    default:
      return 25;
  }
};

const getDayIndex = (dateSelected) => {
  dateSelected = moment(dateSelected);
  const dow = dateSelected.day();
  return dow === 7 ? 0 : dow;
};
const CalendarPage = () => {
  const history = useHistory();
  const [userSelectedDetails, setUserSelectedDetails] = useState(null);
  const [date, setDate] = useState(new Date());
  const [anchorEl, setAnchorEl] = useState(null);
  const [time, setTime] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const [userSelectedData, setUserSelectedData] = useState();
  const [timeData, setTimeData] = useState([]);
  const [userSelectedLocation, setUserSelectedLocation] = useState(null);
  const [userSelectedService, setUserSelectedService] = useState(null);
  const [locationList, setLocationList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [error, setError] = useState();
  const [dateSelector, setDateSelector] = useState(false);
  const [errorData, setErrorData] = useState({
    error: false,
    message: "",
    open: false,
  });
  const [sortedSlots, setSortedSlots] = useState([]);
  const [selectTime, setSelectTime] = useState("00:00 PM ");
  const [availableSlot, setAvailableSlot] = useState(1);
  const [dateList, setDayList] = useState(
    [moment(getLocalStorageData("selectedDate") && new Date(getLocalStorageData("selectedDate"))),
    moment(getLocalStorageData("selectedDate") && new Date(getLocalStorageData("selectedDate"))),
    moment(getLocalStorageData("selectedDate") && new Date(getLocalStorageData("selectedDate"))),
    moment(getLocalStorageData("selectedDate") && new Date(getLocalStorageData("selectedDate"))),
    moment(getLocalStorageData("selectedDate") && new Date(getLocalStorageData("selectedDate"))),
    moment(getLocalStorageData("selectedDate") && new Date(getLocalStorageData("selectedDate"))),
    moment(getLocalStorageData("selectedDate") && new Date(getLocalStorageData("selectedDate")))])
  const [selectedCompany, setSelectedCompany] = useState({});
  const open = Boolean(anchorEl);
  ReactGA.initialize("UA-289359672-01");
  const [
    getCompaniesListApiCallOnCalenderPage,
    setGetCompaniesListApiCallOnCalenderPage,
  ] = useState(false);

  const handleClick = (event, selectedData) => {
    event.preventDefault();
    setUserSelectedData(selectedData);
    setAnchorEl(event.currentTarget);
    setTime(timeToIndex(event.currentTarget.innerText));
    setLocalStorageData("bookingtime", event.currentTarget.innerText);
    setSelectTime(event.currentTarget.innerText);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

 
  useEffect(() => {
   setLocalStorageData("selectedDate", new Date());
    setDate( new Date())
    setDayList([moment(new Date()),
    moment(new Date()).add(1, 'days'),
    moment(new Date()).add(2, 'days'),
    moment(new Date()).add(3, 'days'),
    moment(new Date()).add(4, 'days'),
    moment(new Date()).add(5, 'days'),
    moment(new Date()).add(6, 'days')])
    getSlotsData();
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
    return () => {
      if (history.location.pathname === "/") {
        localStorage.clear();
      }
    };
  }, []);

  const getSlotsData = async () => {
    let companyDetails;
    let locationDetails;
    let serviceDetails;
    let locationList;
    let serviceList;
    let dateDetail;
    try {
      companyDetails = await getLocalStorageData("selectedCompany");
      locationDetails = await getLocalStorageData("selectedLocation");
      serviceDetails = await getLocalStorageData("selectedService");
      locationList = await getLocalStorageData("locationList");
      serviceList = await getLocalStorageData("serviceList");
      dateDetail = await getLocalStorageData("selectedDate");
    } catch (e) {
      // console.log(e.message);
    }

    await setLocationList(locationList);
    await setUserSelectedLocation(locationDetails);
    await setServiceList(serviceList);
    await setUserSelectedService(serviceDetails);
    await setError();
    const isUserDetails =
      locationList &&
      locationList[0]?.value &&
      serviceList &&
      serviceList[0]?.value &&
      dateDetail;
    // const isUserDetails = true;
    setDate(new Date(dateDetail));
    if (isUserDetails) {
      await setLocationList(locationList);
      await setUserSelectedLocation(locationDetails);
      await setServiceList(serviceList);
      await setUserSelectedService(serviceDetails);
      await setError();

    moment.tz.setDefault
    ('Asia/Dubai');
       let formattedDate = moment(dateDetail).format("DD/MM/YYYY");
      // if ( (  moment(dateDetail).format("DD/MM/YYYY") !==
      // moment(new Date()).format("DD/MM/YYYY")) || ( moment(dateDetail).format("DD/MM/YYYY") >
      // moment(new Date()).format("DD/MM/YYYY"))
      // ) {
      //   formattedDate = moment(new Date()).format("DD/MM/YYYY");
      // }
      //  formattedDate = dateDetail
      // console.log(formattedDate, "----------> ");
      try {
        const { data } = await Axios.post(
          `${BASE_URL}/getAvailableStartingTimes`,
          {
            companyId: companyDetails?.value,
            branchId: locationDetails?.value,
            serviceId: serviceDetails?.value,
            date: formattedDate,
          }
        );

        if (data?.message === "success") {
          setDateSelector(true);
        }

        if (data.error === 0) {
          const timeDataList = data.data?.service?.resource[0]?.duration;
          const sortedTimeData = timeDataList.sort((a, b) => {
            if (a.durationTime < b.durationTime) return -1;
            return a.durationTime > b.durationTime ? 1 : 0;
          });
          const getTimeDurationId = sortedTimeData.map((val) => val.id);
          let a = [];
          sortedTimeData.forEach((val) => {
            val.slots.forEach((value) => {
              if (value.available) {
                const currentIndex = a.findIndex((v) => v.time === value.time);
                if (currentIndex > -1) {
                  const tempDuration = [...a[currentIndex].durationTime];
                  tempDuration.push({
                    id: val.id,
                    duration: val.durationTime,
                    price: val.price,
                    currency: val.currency,
                  });
                  a[currentIndex] = {
                    ...a[currentIndex],
                    id: [...[...a[currentIndex].id, val.id]],
                    duration: [
                      ...[...a[currentIndex].duration, val.durationTime],
                    ],
                    durationTime: tempDuration,
                  };
                } else {
                  a.push({
                    ...value,
                    id: [val.id],
                    duration: [val.durationTime],

                    durationTime: [
                      {
                        id: val.id,
                        duration: val.durationTime,
                        price: val.price,
                        currency: val.currency,
                      },
                    ],
                  });
                }
              }
            });
          });
          const temp = a.sort((a, b) => {
            if (a.time < b.time) return -1;
            return a.time > b.time ? 1 : 0;
          });
          setSortedSlots(temp);
          setSelectedIds(getTimeDurationId);
          setTimeData(sortedTimeData);
          setLoading(false);
        } else if (data.error === 1) {
          setLoading(false);
          setErrorData({
            ...errorData,
            error: true,
            message: data.message,
            open: true,
          });
        }
      } catch (error) {
        setLoading(false);
        setError(error?.response?.data.message);
        setErrorData({
          ...errorData,
          error: true,
          message: error?.response?.data.message,
          open: true,
        });
      }
    } else {
      const getCompaniesList = async () => {
        setLocalStorageData("selectedDate", new Date());
        setDate(new Date());
        const { data } = await Axios.post(`${BASE_URL}/getCompanies`);
        if (data.error === 0) {
          const selectCompany = data.data.filter(
            (val) => val.name === "iSKI Club"
          );
          console.log(selectCompany,'******')
          const company = {
            label: "iSKI Club",
            value: "Jywrt74o8uuD3A4yJSvZ",
          };
          
          setSelectedCompany(company);
          setLocalStorageData("selectedCompany", company);
          const list = selectCompany[0]?.branch?.map(({ name, id }) => {
            return {
              label: name,
              value: id,
            };
          });
          setLocationList(list);
          {   setUserSelectedLocation(list[0])};
          setLocalStorageData("selectedLocation", list[0]);
          setLocalStorageData("locationList", list);
          setError();
          const serviceListItem = selectCompany[0]?.branch[0].services?.map(
            ({ name, id }) => {
              return {
                label: name,
                value: id,
              };
            }
          );
          setServiceList(serviceListItem);
          setUserSelectedService(serviceListItem) 
          setLocalStorageData("selectedService", serviceListItem[0]) 
          setLocalStorageData("serviceList", serviceListItem);

          const formattedDate = moment(new Date()).format("DD/MM/YYYY");
          try {
              const { data } = await Axios.post(
                `${BASE_URL}/getAvailableStartingTimes`,
                {
                   companyId: company?.value,
                  branchId: list[0]?.value,
                  serviceId: serviceListItem[0]?.value,
                  date: formattedDate,
                }
              );
            if (data?.message === "success") {
              setDateSelector(true);
            }

            if (data.error === 0) {
              const timeDataList = data.data?.service?.resource[0]?.duration;
              const sortedTimeData = timeDataList.sort((a, b) => {
                if (a.durationTime < b.durationTime) return -1;
                return a.durationTime > b.durationTime ? 1 : 0;
              });
              const getTimeDurationId = sortedTimeData.map((val) => val.id);
              let a = [];
              sortedTimeData.forEach((val) => {
                val.slots.forEach((value) => {
                  if (value.available) {
                    const currentIndex = a.findIndex(
                      (v) => v.time === value.time
                    );

                    if (currentIndex > -1) {
                      const tempDuration = [...a[currentIndex].durationTime];
                      tempDuration.push({
                        id: val.id,
                        duration: val.durationTime,
                        price: val.price,
                        currency: val.currency,
                      });
                      a[currentIndex] = {
                        ...a[currentIndex],
                        id: [...[...a[currentIndex].id, val.id]],
                        duration: [
                          ...[...a[currentIndex].duration, val.durationTime],
                        ],
                        durationTime: tempDuration,
                      };
                    } else {
                      a.push({
                        ...value,
                        id: [val.id],
                        duration: [val.durationTime],

                        durationTime: [
                          {
                            id: val.id,
                            duration: val.durationTime,
                            price: val.price,
                            currency: val.currency,
                          },
                        ],
                      });
                    }
                  }
                });
              });
              const temp = a.sort((a, b) => {
                if (a.time < b.time) return -1;
                return a.time > b.time ? 1 : 0;
              });

              setSortedSlots(temp);
              setSelectedIds(getTimeDurationId);
              setTimeData(sortedTimeData);
              setLoading(false);
            } else if (data.error === 1) {
              setLoading(false);
              setErrorData({
                ...errorData,
                error: true,
                message: data.message,
                open: true,
              });
            }
          } catch (error) {
            setLoading(false);
            setError(error?.response?.data.message);
            setErrorData({
              ...errorData,
              error: true,
              message: error?.response?.data.message,
              open: true,
            });
          }
        }
      };
      getCompaniesList();
      setGetCompaniesListApiCallOnCalenderPage(true);
    }
  };

  const handleLocationChange = async (e) => {
    const [selectedLocation] = locationList.filter(
      (val) => val.value === e.target.value
    );
    setLocationList();
    setUserSelectedLocation(selectedLocation);
    setLocalStorageData("selectedLocation", selectedLocation);
    setLoading(true);
    try {
      const { data } = await Axios.post(`${BASE_URL}/getServices`, {
        companyId: selectedCompany?.value,
        branchId: e.target.value,
      });

      if (data.error === 0) {
        const services = data.data.map(({ name, id }) => {
          return {
            label: name,
            value: id,
          };
        });
        setUserSelectedService(services);
        setLocalStorageData("serviceList", services);
        setUserSelectedService(null);
        setLocalStorageData("selectedService", "");
        setTimeData();
        setSelectedIds([]);
        setLoading(false);
      } else if (data.error === 1) {
        setLoading(false);
        setErrorData({
          ...errorData,
          error: true,
          message: data.message,
          open: true,
        });
      }
    } catch (error) {
      setLoading(false);
      setErrorData({
        ...errorData,
        error: true,
        message: error.response?.data?.message,
        open: true,
      });
    }
  };

  const handleServiceChange = (e) => {
    const [selectedService] = serviceList.filter(
      (val) => val.value === e.target.value
    );
    setUserSelectedService(selectedService);
    setLocalStorageData("selectedService", selectedService);
    setLoading(true);
    getSlotsData();
  };

  const changeDuration = (id) => {
    let updatedList = [];
    updatedList.push(id);
    setSelectedIds(updatedList);
  };

  const changeDay = (newDay) => {
  if (
    (
      moment(new Date(newDay)).format("DD/MM/YYYY") ==
        moment(new Date()).format("DD/MM/YYYY") ||
      moment(new Date(newDay)).format("DD/MM/YYYY") >
        moment(new Date()).format("DD/MM/YYYY")
    )
  ) {
    // console.log('IFFFFF',newDay)
    setDate(newDay);
    setLocalStorageData("selectedDate", newDay);
    setLoading(true);
    getSlotsData();
  } else {
    // console.log('IFFFFFElse',new Date())
    setDate(new Date());
    setLocalStorageData("selectedDate", new Date());
    setLoading(true);
    getSlotsData();
  }
  };
  const nextWeek = () => {
    const selectedDate = dateList.map((val) => {
      return moment(moment(val).add(7, "days")?.toDate());
    });
    setDayList(selectedDate);
    setDate(new Date(selectedDate[0]));
    setLocalStorageData("selectedDate", new Date(selectedDate[0]));
    setLoading(true);
    getSlotsData();
  };
  const prevWeek = () => {
    const selectedDate = dateList.map((val) => {
      return moment(moment(val).subtract(7, "days")?.toDate());
    });

    setDayList(selectedDate);
     if (
       !(
         moment(new Date(selectedDate[0])).format("DD/MM/YYYY") !==
           moment(new Date()).format("DD/MM/YYYY") ||
         moment(new Date(selectedDate[0])).format("DD/MM/YYYY") >
           moment(new Date()).format("DD/MM/YYYY")
       )
     ) {
       setDate(new Date(selectedDate[0]));
       setLocalStorageData("selectedDate", new Date(selectedDate[0]));
       setLoading(true);
       getSlotsData();
     } else {
       setDate(new Date());
       setLocalStorageData("selectedDate", new Date());
       setLoading(true);
       getSlotsData();
     }
   
  };
  const handleDateChange = (newDate) => {
    const dateListItems = [];
    for (let index = 0; index < 7; index++) {
      dateListItems.push(moment(moment(newDate).add(index, "days")?.toDate()));
    }
    setDayList(dateListItems);
    setDate(new Date(dateListItems[0]));
    setLocalStorageData("selectedDate", new Date(dateListItems[0]));
    setLoading(true);
    getSlotsData();
  };

  const handleBack = () => {
    history.goBack();
  };
  const processDayTime = (slotTime) => {
    const slotHours = slotTime.substring(0, 2);

    if (slotHours > 11 && slotHours <= 23) {
      return "Afternoon";
    } else {
      return "Morning";
    }
  };

  const processTime = (str) => {
    const gettime = `${str.substr(0, 2) + ":" + str.substr(2)}`;
    const formatedTime = moment(gettime, "HH:mm A").format("hh:mm A");
    return formatedTime;
  };
  const handleSelectTime = (serviceId, price, durationTime) => {
    setLocalStorageData("serviceId", serviceId);
    setLocalStorageData("servicePrice", price);
    setLocalStorageData("durationTime", durationTime);
    history.push("/checkout");
  };
  const handleCloseSnakbar = () => {
    setErrorData({ error: false, message: "", open: false });
  };
  const ifSelected = (slots, selectedSlots) => {
    const interaction = slots.filter((element) =>
      selectedSlots.includes(element)
    );
    if (interaction.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (selectedIds?.length) {
      const slotLengthValue = sortedSlots.filter((val) =>
        ifSelected(val.id, selectedIds)
      );
      setAvailableSlot(slotLengthValue?.length);
    }
  }, [selectedIds]);
  const classes = useStyles();
  return (
    <>
      <MetaTitle title="Calendar l Iski l Wakesurfing Wakeboarding" />
      <Toaster
        open={errorData.open}
        error={errorData.error}
        message={errorData.message}
        handleCloseSnakbar={handleCloseSnakbar}
      />

      <div className={classes.fullPage}>
        <div className={classes.restOfPage}>
          <div className={classes.backBtnWrapper}>
            <Grid item xs={5} lg={6} md={6}>
              <Grid container className={classes.itemsList}>
                <ArrowIcon onClick={handleBack} />
              </Grid>
            </Grid>
            <Grid item xs={7} lg={6} md={6}>
              <Grid container className={classes.itemsList}>
                <p className={classes.bookingText}>Booking</p>
              </Grid>
            </Grid>
          </div>
          <div className={classes.serviceAndLocationSelector}>
            <div className={classes.backAndSelector}>
              <div className={classes.Selectors}>
                <Selector
                  handleChange={handleLocationChange}
                  value={userSelectedLocation?.value || ""}
                  label="Location"
                  options={(locationList && locationList) || []}
                  getCompaniesListApiCallOnCalenderPage={
                    getCompaniesListApiCallOnCalenderPage
                  }
                />
              </div>
              <div className={classes.verticalLine}></div>
              <div className={classes.Selectors}>
                <Selector
                  handleChange={handleServiceChange}
                  value={userSelectedService?.value || "No service Available"}
                  label="Sport"
                  options={(serviceList && serviceList) || []}
                  getCompaniesListApiCallOnCalenderPage={
                    getCompaniesListApiCallOnCalenderPage
                  }
                />
              </div>
            </div>
          </div>
          {console.log(date,'2222',dateList)}
          <DaySelector
            date={date}
            selected={getDayIndex(date)}
            onClick={(day) => changeDay(day)}
            handleChange={(date) => handleDateChange(date)}
            prevWeek={prevWeek}
            nextWeek={nextWeek}
            dateList={dateList}
            dateSelector={dateSelector}
          />
          {!loading ? (
            <>
              <div className={classes.optionsHolder}>
                <p className={classes.title}>Filter by Duration</p>
              </div>
              <div className={classes.DurationChipsContainerParent}>
                <div className={classes.DurationChipsContainer}>
                  {!error &&
                    timeData?.map((service, index) => {
                      console.log(userSelectedService , "wwwwwwwwwwwwwww")
                      return (
                        <DurationChip
                          key={service.id}
                          durationPrice={
                            +service.price + " " + service.currency
                          }
                          durationLabel={userSelectedService.label === "Windsurfing"? [service.durationTime + " " + "Mins"] : Resourcetimings[service.durationTime]}
                          selected={selectedIds?.includes(service.id)}
                          onClick={() => changeDuration(service.id)}
                        />
                      );
                    })}
                </div>
              </div>
              <div className={classes.optionsHolder}>
                <p className={classes.title}>Available Starting Times</p>
                {availableSlot > 0 ? (
                  <>
                    <div className={classes.subOptionsHolder}>
                      <p className={classes.subTitle}>Morning</p>
                      <div className={classes.chipHolder}>
                        {error ? (
                          <div>{error}</div>
                        ) : (
                          <>
                            {sortedSlots.map((servicesData) => {
                              if (
                                processDayTime(servicesData.time) ===
                                  "Morning" &&
                                ifSelected(servicesData.id, selectedIds) &&
                                processTime(servicesData.time).includes("AM")
                              ) {
                                return (
                                  <TimeChip
                                    key={servicesData.time}
                                    time={`${processTime(servicesData.time)}`}
                                    onClick={(e) =>
                                      handleClick(e, servicesData)
                                    }
                                    selectTime={selectTime}
                                  />
                                );
                              }
                            })}
                          </>
                        )}
                      </div>
                    </div>
                    <div className={classes.subOptionsHolder}>
                      <p className={classes.subTitle}>Afternoon</p>
                      <div className={classes.chipHolder}>
                        {error ? (
                          <div>{error}</div>
                        ) : (
                          <>
                            {sortedSlots.map((servicesData) => {
                              if (
                                processDayTime(servicesData.time) ===
                                  "Afternoon" &&
                                ifSelected(servicesData.id, selectedIds) &&
                                processTime(servicesData.time).includes("PM")
                              ) {
                                return (
                                  <TimeChip
                                    key={servicesData.time}
                                    time={`${processTime(servicesData.time)}`}
                                    onClick={(e) =>
                                      handleClick(e, servicesData)
                                    }
                                    selectTime={selectTime}
                                  />
                                );
                              }
                            })}
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      <p className={classes.alertText}>
                        {" "}
                        *A Session is usually between 13-15 mins which typically
                        consists of 6 runs through the slalom course.
                      </p>
                    </div>
                  </>
                ) : (
                  <div className={classes.unavailableSlots}>
                    Ughh, Seems like we are fully booked today. Join on us on
                    another day?
                  </div>
                )}
              </div>

              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div className={classes.popoverDurationHolder}>
                  {userSelectedData?.durationTime.map((serviceIds) => {
                    if (selectedIds.includes(serviceIds.id)) {
                      return (
                        <TimeChip
                          key={serviceIds.id}
                          time={userSelectedService.label === "Windsurfing"? [serviceIds.duration + " " + "Mins"] : Resourcetimings[serviceIds.duration]}
                          // time={Resourcetimings[serviceIds.duration]}
                          onClick={() =>
                            handleSelectTime(
                              serviceIds?.id,
                              +serviceIds?.price + " " + serviceIds?.currency,
                              serviceIds?.duration
                            )
                          }
                        />
                      );
                    }
                  })}
                </div>
              </Popover>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                minHeight: "100px",
              }}
            >
              <CircularProgress className={classes.loadingIcon} size={50} />
            </div>
          )}
        </div>

        <Footer />
      </div>
    </>
  );
};

export { CalendarPage as default };
